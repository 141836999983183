body {
  margin: 0;
  padding: 0;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

.dashboard-html {
  height: 100%;
}

.dashboard-html body,
.dashboard-html #layout {
  height: 100%;
}

.global-notice {
  display: block;
  padding: 10px;
  border: 1px solid #faebcc;
  color: #8a6d3b;
  background-color: #fcf8e3;
  text-align: center;
}

#layout {
  display: block;
  position: relative;
  min-height: calc(100vh - 50px);
  overflow: hidden;
}

#simpleHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: #F4F4F4;
}

#desktopHeader .col>a.help-button {
  color: #999999;
  line-height: 3px;

  .help-button__icon {
    border: solid 1px #999999;
    border-radius: 50%;
    padding: 1px;
    margin-top: 2px;
  }
}

.simple-header {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  height: 50px;
  justify-content: space-between;
  align-items: center;

  .menu-button {
    cursor: pointer;
    margin-left: 15px;
  }

  .back-button {
    color: #666666;
    text-decoration: none;
    margin-left: 15px;
  }

  .help-icon {
    cursor: pointer;
  }

  .logo {
    font-size: 0;
    img {
      height: 30px;
    }
  }

  .filler {
    width: 39px;
  }
}


#mobileMenuOverlay {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 59999;
  background-color: rgb(51, 51, 51);
  opacity: 0;
  display: none;
  -webkit-transition: opacity 200ms ease-out;
  -moz-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;

  &.show {
    opacity: 0.5;
    display: block;
  }
}

#mobileMenu {
  display: block;
  position: absolute;
  -webkit-transform: translateX(-250px);
  -moz-transform: translateX(-250px);
  transform: translateX(-250px);
  top: 0px;
  bottom: 0px;
  width: 250px;
  padding: 10px 0px;
  background-color: #fff;
  z-index: 60000;
  -webkit-transition: transform 200ms ease-out;
  -moz-transition: transform 200ms ease-out;
  -o-transition: transform 200ms ease-out;
  transition: transform 200ms ease-out;
  overflow: hidden;

  > * {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: rgb(102, 102, 102);
    font-size: 16px;
  }

  script {
    display: none;
  }

  .user-image {
    background-position: center center;
    background-size: cover;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
    float: right;
    margin-right: 15px;
    margin-top: -3px;
  }

  > .divider {
    cursor: none;
    height: 1px;
    padding: 0px;
    background-color: rgb(238, 238, 238);
  }

  &.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}

#mobileMenu.main-menu {
  > * {
    padding: 15px 0px 15px 15px;
  }

  > .divider {
    padding: 0;
  }
}

#mobileMenu.dashboard-menu {
  padding-top: 0;
  width: 200px;
  -webkit-transform: translateX(-200px);
  -moz-transform: translateX(-200px);
  transform: translateX(-200px);
  overflow: auto;

  @media (min-width: 1225px) {
    position: fixed;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  &.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  script {
    display: none;
  }
}

#mobileHeader {
  background-color: #F4F4F4;
}

#mobileHeader.mobile-dashboard-header {
  .menu-button {
    padding: 8px 8px 5px 8px;
  }

  .mobile-dashboard-logo-container {
    width: 100%;
    padding: 8px 5px 2px 5px;
    text-align: center;

    > .logo {
      height: 20px;
      width: auto;
    }
  }

  .dashboard-menu-logo-container__logo {
    height: 20px;
    width: 20px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
  }

  .dashboard-menu-logo-container__logo__image {
    height: 100%;
    width: auto;
  }
}

#mobileMenu.dashboard-menu {
  > * {
    color: white;
  }
}

#mobileMenu .dashboard-menu-logo-container {
  padding: 15px 10px 10px 10px;
  text-align: center;
  margin-top: -5px;

  &__logo {
    border-radius: 50%;
    background-color: white;
    margin: auto;
    height: 80px;
    width: 80px;
    border: solid 10px white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
      width: 90%;
      height: auto;
    }
  }
}

.dashboard-content-container {
  @media (min-width: 1225px) {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}

#desktopHeader {
  background-color: #F4F4F4;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  height: 50px;
  vertical-align: middle;
  justify-content: space-between;

  .row {
    width: 100%;
    display: flex;
  }

  .col {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;

    .logo {
      display: inline-block;
      line-height: 0px;
      margin-left: 12px;

      img {
        padding: 5px 5px 10px 5px;
        height: 30px;
      }
    }

    > a {
      display: block;
      white-space: nowrap;
      line-height: 50px;
      font-size: 14px;
      color: #1E1E1E;
      text-decoration: none;
      margin: 0px clamp(4px, 1.8%, 40px);

      @media (min-width: 1530px) {
        margin: 0px clamp(5px, 2%, 40px);
        font-size: 16px;
      }

      @media (min-width: 1640px) {
        margin: 0px clamp(5px, 3%, 40px);
      }
      
      @media (min-width: 1800px) {
        margin: 0px clamp(5px, 4%, 40px);
      }
    }

    .divider {
      background-color: #AAAAAA;
      height: 20px;
      margin: 15px 0px;
      width: 1px;
      
      @media (min-width: 1530px) {
        margin: 0px clamp(5px, 2%, 40px);
      }

      @media (min-width: 1640px) {
        margin: 0px clamp(5px, 3%, 40px);
      }
      
      @media (min-width: 1800px) {
        margin: 0px clamp(5px, 4%, 40px);
      }
    }

    .help-icon {
      vertical-align: middle;
      cursor: pointer;
    }

    &--root {
      flex: unset;
      flex-grow: 1;
      justify-content: space-between;

      > a {
        margin: 0;

        @media (min-width: 1530px) {
          margin: 0;
        }
  
        @media (min-width: 1640px) {
          margin: 0;
        }
        
        @media (min-width: 1800px) {
          margin: 0;
        }
      }
    }
  }

  .center {
    justify-content: space-around;
    margin: 0 0 0 20px;

    &--root {
      flex-grow: 1;
      margin: 0;
    }
  }

  .right {
    margin-left: auto;
    margin-right: 20px;
    justify-content: flex-end;

    &--root {
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  .user-image {
    background-position: center center;
    background-size: cover;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: -7px;
    margin-right: 8px;
    position: relative;

    &--with-arrow {
      &:after {
        content: '\25BE';
        display: block;
        position: absolute;
        right: -18px;
        top: 2px;
        font-size: 16px;
        color: #9B9B9B;
      }
    }
  }

  .user-menu {
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    color: rgb(102, 102, 102);
    text-decoration: none;
    padding: 0 15px;
    cursor: pointer;

    &__dropdown {
      display: none;
      position: absolute;
      background: #fff;
      z-index: 999;
      padding: 0;
      right: 0;
      top: 30px;
      border-radius: 15px;
      border: 1px solid #efefef;
      overflow: hidden;

      &__link {
        display: block;
        color: rgb(102, 102, 102);
        text-decoration: none;
        padding: 7px 20px;
        font-size: 16px;
        white-space: nowrap;

        &:hover {
          background: rgb(253, 240, 240);
        }

        &__unread-count {
          background: rgb(235, 0, 140);
          border-radius: 10px;
          font-size: 12px;
          color: #fff;
          margin-left: 5px;
          width: 16px;
          height: 16px;
          display: inline-block;
          text-align: center;
        }
      }
    }

    &--showed {
      .user-menu__dropdown {
        display: block;
      }
      .user-menu__unread-count {
        display: none;
      }
    }

    &__unread-count {
      position: absolute;
      top: -9px;
      right: 14px;
      background: rgb(235, 0, 140);
      color: #fff;
      border: 2px solid #fff;
      border-radius: 10px;
      width: 16px;
      height: 16px;
      text-align: center;
      font-size: 12px;
      z-index: 2;
    }
  }
}

#content {
  display: block;
}

#layoutFiller {
  width: 100%;
  height: 200px;
}

#footer {
  display: block;
  background-color: #f4f4f4;
  border-top: 1px solid #f4f4f4;

  > .section {
    display: block;
    text-align: center;
    padding: 30px 10px 40px 10px;
    box-sizing: border-box;

    .main-quote {
      font-size: 18px;
      color: #888;
      display: block;
      margin-bottom: 10px;
    }

    .author {
      font-size: 14px;
      color: #888;
    }

    .copyright {
      font-size: 12px;
      color: #888;
      line-height: 22px;
    }
  }

  > .action-section {
    background-color: #fbfafa;
    text-align: left;

    display: flex;
    justify-content: center;

    .block {
      text-align: center;
      display: flex;
      flex: 1;
      padding: 20px;

      .text {
        .header {
          display: block;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .body {
          display: block;
        }

        a {
          display: block;
          color: rgb(235, 0, 140);
          padding: 10px 0px;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
        }
      }

      email-subscription {
        display: block;
        width: 100%;
        max-width: 320px;
        margin: 0px auto;
      }
    }

    @media (max-width: 900px) {
      & {
        flex-direction: column;
      }

      .block {
        flex-direction: column;

        div {
          text-align: center;
          display: block;
          margin-bottom: 5px;
        }
      }
    }

    @media (min-width: 901px) {
      .block {
        text-align: left;

        div {
          margin: 0px 5px;
        }
      }
    }
  }

  .button-section {
    > a {
      text-decoration: none;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      font-weight: bold;
      margin: 0px 10px;
      font-size: 14px;
      color: rgb(153, 153, 153);
      text-align: center;
    }

    @media (max-width: 660px) {
      > a {
        display: block;
        padding: 10px 0px;
        margin-top: 10px;
      }

    }

    @media (min-width: 661px) {
      > a {
        display: inline-block;
        padding: 10px 60px;
      }
    }
  }

  .link-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    > a {
      font-size: 14px;
      color: #888;
      text-decoration: none;
      padding-right: 2px;
    }

    @media (max-width: 480px) {
      & {
        flex-direction: column;
      }

      > a {
        display: block;
        margin: 10px auto;
      }
    }

    @media (min-width: 481px) {
      > a {
        display: inline-block;
      }

      > a:first-child:before {
        display: none !important;
      }

      > a:before {
        content: '';
        width: 3px;
        height: 3px;
        margin: 10px;
        vertical-align: middle;
        margin-bottom: 12px;
        border-radius: 1.5px;
        background-color: #888;
        display: inline-block;
      }
    }
  }
}

@media (max-width: 1224px) {
  #desktopHeader {
    display: none;
  }

  #mobileHeader {
    display: flex;
  }
}

@media (min-width: 1225px) {
  #desktopHeader {
    display: flex;
  }

  #mobileHeader {
    display: none;
  }
}

.one-page-notice {
  display: block;
  background-color: white;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 150px 10% 50px 10%;
  text-align: center;
  font-size: 16px;

  a {
    color: #ec7070;
    text-decoration: none;
  }

  .error-title-bubble {
    display: block;
  }

  .error-header-placeholder {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .error-message {
    font-size: 16px;
    font-weight: bold;
  }

  .error-title {
    background-color: #ec7070;
    border-radius: 16px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 10px 30px;
    position: relative;
  }

  .error-title::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 70%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #ec7070 transparent transparent transparent;
  }

  .body {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #333333;
  }

  .action-button-link {
    display: block;
  }

  .action-button {
    background-color: #ec7070;
    border: 0;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    padding: 10px 20px;
  }
}

.responsive-container {
  display: block;
  background-color: #fff;
  padding-bottom: 50px;
}

@media (max-width: 480px) {
  .responsive-container {
    margin: 50px 5% 50px 5%;
  }
}

@media (min-width: 480px) {
  .responsive-container {
    margin: 50px 10% 50px 10%;
  }
}

@media (min-width: 768px) {
  .responsive-container {
    margin: 50px 15% 50px 15%;
  }
}

@media (min-width: 1224px) {
  .responsive-container {
    margin: 50px 33% 50px 33%;
  }
}

.seo-content {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  .seo-content__banner {
    background-color: white;
    color: rgb(102, 102, 102);
    text-align: center;
    padding: 50px 20px;
    position: relative;
    overflow: hidden;

    .seo-content__banner__image {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;

      @media (min-width: 480px) {
        width: 100%;
        height: auto;
      }
    }


    .seo-content__banner__overlay {
      background-color: white;
      opacity: .82;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .seo-content__banner__content {
      position: relative;
      z-index: 1;

      .seo-content__banner__content__header {
        color: rgb(70, 70, 70);
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 15px;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      .seo-content__banner__content__subheader {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 5px;

        @media (min-width: 992px) {
          padding: 0 30%;
        }

        @media (min-width: 1224px) {
          padding: 0 35%;
        }
      }

      .seo-content__banner__content__subheader:before {
        content: '';
        width: 3px;
        height: 3px;
        margin: 10px 5px 10px 10px;
        vertical-align: middle;
        margin-bottom: 12px;
        border-radius: 1.5px;
        background-color: #888;
        display: inline-block;
      }

      .seo-content__banner__content__link {
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px dotted rgb(102, 102, 102);
        margin: 20px 10px 0 10px;
      }

      .seo-content__banner__content__lead {
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 2px;
      }
    }
  }
}

#simpleFooter {
  padding: 10px;
  margin: 0px 0px 10px 0px;
  border-bottom: 1px dotted #eee;
}

#helpContainer {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 19px;
  right: 19px;
  z-index: 9999;
}

#helpBtn {
  min-width: 45px;
  height: 45px;
  border-radius: 200px;
  background: rgba(255, 255, 255, 1);
  border: none;
  box-shadow: 0px 0.75px 3px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  padding-top: 5px;
  visibility: hidden;
}

.new-badge {
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  padding: 2px 4px;
  color: white;
  background-color: var(--giviki-color);
}

.donate-btn {
  margin: 0px clamp(4px, 1.8%, 40px);
  background-color: var(--giviki-color);
  border-radius: 30px;
}

.giviki-navbar-donate-btn {
  padding: 10px 30px;
  line-height: 40px;
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.maintenance-ribbon-container {
  padding-bottom: 0;
  background-color: rgb(244, 244, 244);

  &__ribbon {
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    color: white;
    background-color: #0082FF;
    margin: 0;

    @media (min-width: 1224px) {
      font-size: 14px;
    }
  }
}
